import React, { useState } from "react";
import "./Sidebar.css";
import homeLogo from "../imgs/homeLogo.png";
import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
const Sidebar = () => {
  const [selected, setSelected] = useState(0);
  const [expanded, setExpaned] = useState(true)
  const navigate=useNavigate();
  const handleLogoClick = () => {
    navigate("/");
    setSelected(0);
  };
  const sidebarVariants = {
    true: {
      left : '0'
    },
    false:{
      left : '-60%'
    }
  }
  console.log(window.innerWidth)
  return (
    <>
      <div className="bars" style={expanded?{left: '60%'}:{left: '5%'}} onClick={()=>setExpaned(!expanded)}>
        <UilBars />
      </div>
    <motion.div className='sidebar'
    variants={sidebarVariants}
    animate={window.innerWidth<=768?`${expanded}`:''}
    >
      {/* logo */}
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src={homeLogo} alt="logo" style={{width : 100, height: 60}} />
        {/* <span>
          Sh<span>o</span>ps
        </span> */}
      </div>
      <div className="menu">
      {SidebarData.map((item, index) => {
  return (
    <div
      className={selected === index ? 'menuItem active' : 'menuItem'}
      key={index}
      onClick={() => {
        setSelected(index);
        navigate(item.url); // Navigate to the specified URL
      }}
    >
      <item.icon />
      <span>{item.heading}</span>
    </div>
  );
})}
      </div>
    </motion.div>
    </>
  );
};
export default Sidebar;
