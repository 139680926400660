import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "./MainDash.css";
import { UilUserCircle } from "@iconscout/react-unicons";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import axios from 'axios';
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../Api/Constant";

const MainDash = (props) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [productDialog, setProductDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role_id: 2,
    department_id: 2,
  });
  const [error, setError] = useState('');

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    setShowLogoutDialog(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("token");
    setShowLogoutDialog(false);
    props.onLogout();
    navigate("/login");
  };

  const cancelLogout = () => {
    setShowLogoutDialog(false);
  };

  const onInputChange = (e, fieldName) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BASE_URL}admin/auth/register`, formData);
      if(response.data.message=="Admin Created Successfully."){
        setProductDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Successfully Created Admin",
          life: 3000,
        });
      }
    } catch (error) {
      setError(error.response.data.message)
    }
  };

  return (
    <div className="MainDash">
      <Toast ref={toast} />
      <div className="header">
        <h1>{props.title}</h1>
        <div className="mainLogo" onClick={toggleDropdown}>
          <UilUserCircle />
          {dropdownVisible && (
            <div className="dropdown">
              <button onClick={() => {setProductDialog(true)}}>Create Admin Account</button>
              {/* <button>Profile</button> */}
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
      </div>

      {props.children}

      <Dialog
        visible={showLogoutDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Logout"
        modal
        onHide={() => setShowLogoutDialog(false)}
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={cancelLogout}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={confirmLogout}
            />
          </div>
        }
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Are you sure you want to logOut?</span>
        </div>
      </Dialog>
      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Create New Admin"
        modal
        className="p-fluid"
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              outlined
              onClick={() => setProductDialog(false)}
            />
            <Button
              label="Submit"
              icon="pi pi-check"
              severity="danger"
              onClick={handleSubmit}
            />
          </div>
        }
        onHide={() => setProductDialog(false)}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={formData.name}
            onChange={(e) => onInputChange(e, "name")}
            required
          />
        </div>
        <div className="field">
          <label htmlFor="email" className="font-bold">
            EmailId
          </label>
          <InputText
            id="email"
            value={formData.email}
            onChange={(e) => onInputChange(e, "email")}
            required
          />
        </div>
        <div className="field">
          <label htmlFor="password" className="font-bold">
            Password
          </label>
          <InputText
            id="password"
            type="password"
            value={formData.password}
            onChange={(e) => onInputChange(e, "password")}
            required
          />
        </div>
        {error && <h4 style={{color: 'red', fontSize: '13px'}}>{error}</h4>}
      </Dialog>
    </div>
  );
};

export default MainDash;
