import './App.css'
import Sidebar from './components/Sidebar';
import WebRoutes from './WebRoutes';
import Login from './components/Login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('token');
    setIsLoggedIn(!!accessToken);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (<>
    {!isLoggedIn ? (
      <Login onLogin={() => setIsLoggedIn(true)} />
    ) : (
      <div className="App">
        <div className="AppGlass">
          <Sidebar />
          <WebRoutes onLogout={handleLogout}/>
        </div>
      </div>
    )}
  </>
  );
}
export default App;
