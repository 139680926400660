import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../../Api/Constant";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault()
    setError('');
    if (!isEmailValid(email)) {
      setError('Invalid email format');
      return;
    }
    try {
      const response = await axios.post(`${BASE_URL}admin/auth/login`, {
        email,
        password,
      });
      if(response.data.data.token !== undefined){
        const token = response.data.data.token;
        localStorage.removeItem('token')
        localStorage.setItem('token', token);
        onLogin();
        navigate('/')
      }
    } catch (error) {
      console.error('Login failed', error);
      setError(error.response.data.message)
    }
  };

  return (
    <div className="LoginContainer">
      <div className="Login">
        <div className="Login_Container">
          <h1>Login</h1>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <div style={{ width: "20%", margin: "auto" }}>
            <Button
              variant="secondary"
              type="button" // Change type to "button"
              className="btntheme"
              onClick={handleLogin}
            >
              Submit
            </Button>
          </div>
          {error && <h4 style={{color: 'red', fontSize: '13px'}}>{error}</h4>}
        </div>
      </div>
    </div>
  );
};

export default Login;
