import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import usePagination from "../Pagination/Pagination";
// import { Paginator } from "primereact/paginator";
import { Pagination } from "@mui/material";
import { BASE_URL } from "../../Api/Constant";

export default function Question() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };
  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [toggleStatus, setToggleStatus] = useState("");
  const [dataCount, setDataCount] = useState("");
  const [data, setData] = useState([]);
  let [page, setPage] = useState(1);
  const perPage = 15;
  const count = Math.ceil(dataCount / perPage);
  const _DATA = usePagination(data, perPage);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}post/all?is_paid=1&is_answered=0&limit=${perPage}&page=${page}&sortBy=DESC`
      );
      const data = response.data.data.rows;
      setDataCount(response.data.data.count);
      setProducts(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [page, perPage, toggleStatus]);

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };
  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };
  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };
  const saveProduct = async () => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      try {
        const response = await axios.put(
          `${BASE_URL}post/${product.id}`,
          { answer: product.answer },
          {
            headers: {
              "x-access-token": `Bearer ${authToken}`,
            },
          }
        );
        if (response.data.success) {
          let updatedProducts = products.filter((pro) => pro.id !== product.id);
          setProducts(updatedProducts);
          setProductDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Answer Updated",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Error updating answer:", error);
      }
    }
  };
  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };
  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };
  const deleteProduct = async () => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      try {
        const response = await axios.delete(`${BASE_URL}post/${product.id}`, {
          headers: {
            "x-access-token": `Bearer ${authToken}`, // Replace with your actual token
          },
        });
        if (response.data.success) {
          let _products = products.filter((val) => val.id !== product.id);
          setProducts(_products);
          setDeleteProductDialog(false);
          setProduct(emptyProduct);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Question Deleted",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };
  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Questions Deleted",
      life: 3000,
    });
  };

  const toggleButton = async (id) => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      try {
        const response = await axios.post(
          `${BASE_URL}post/private`,
          {
            post_id: id,
          },
          {
            headers: {
              "x-access-token": `Bearer ${authToken}`,
            },
          }
        );
        if (response.data.success) {
          setToggleStatus(response.data.message);
        }
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };
  const onInputChange = (e, answer) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${answer}`] = val;
    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {/* <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          style={{ margin: "0px 10px" }}
        /> */}
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
        />
      </div>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        icon="pi pi-upload"
        className="p-button-help"
        onClick={exportCSV}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
          style={{ margin: "0px 10px" }}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Q&A</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        ></Toolbar>
        <DataTable
          ref={dt}
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="question"
            header="Question"
            sortable
            style={{ minWidth: "12rem" }}
            body={(rowData) => (
              <div>
                <div style={{ fontWeight: "bolder" }}>{rowData.question}</div>
                <div>{rowData.answer}</div>
              </div>
            )}
          />
          <Column
            field="username"
            header="Username"
            sortable
            style={{ minWidth: "8rem", textTransform: "capitalize" }}
            body={(rowData) => (
              <div>
                <div style={{ fontWeight: "bolder" }}>
                  {rowData.post_user?.user_name}
                </div>
              </div>
            )}
          ></Column>
          <Column
            field="public"
            header="Post Private/Public"
            style={{ minWidth: "8rem", textTransform: "capitalize" }}
            body={(rowData) => (
              <div>
                {rowData.is_private ? (
                  <Button
                    label="Private"
                    outlined
                    onClick={() => {
                      toggleButton(rowData.id);
                    }}
                  />
                ) : (
                  <Button
                    label="Public"
                    outlined
                    onClick={() => {
                      toggleButton(rowData.id);
                    }}
                  />
                )}
              </div>
            )}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
        <Pagination
          count={products.length}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </div>
      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Post  Answer"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Question
          </label>
          <InputText
            id="name"
            value={product.question}
            onChange={(e) => onInputChange(e, "name")}
            disabled
          />
          {/* {submitted && !product.employee_name && <small className="p-error">question is required.</small>} */}
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Answer
          </label>
          <InputText
            id="answer"
            value={product.answer}
            onChange={(e) => onInputChange(e, "answer")}
            required
            rows={3}
            cols={20}
          />
        </div>
      </Dialog>
      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && <span>Are you sure you want to delete?</span>}
        </div>
      </Dialog>
      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && <span> you want to delete the selected products?</span>}
        </div>
      </Dialog>
    </div>
  );
}
