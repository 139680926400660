import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import MainDash from './components/MainDash/MainDash'
import User from './components/User/User'
import Question from './components/Question/Question'
import Login from './components/Login/Login'
import Payment from './components/Payment/Payment'
import ResolvedQuestion from './components/ResolvedQuestion/ResolvedQuestion'
import Reviews from './components/Reviews/Reviews'
import Privacy from './components/Privacy/Privacy'
const WebRoutes = (props) => {
  console.log(props, '++')
  const accessToken = localStorage.getItem('token');
  return (
    <div className='webroutes'>
      <Routes>
        <Route
          exact
          path="/login"
          element={accessToken ? <Navigate to='/' /> :
            <Login />
          }
        />
        <Route
          exact
          path="/user"
          element={accessToken ?
            <MainDash title="User" onLogout={props.onLogout}>
              <User />
            </MainDash> :
            <Navigate to='/login' />
          }
        />
        <Route
          exact
          path="/payments"
          element={accessToken ?
            <MainDash title="Payment" onLogout={props.onLogout}>
              <Payment />
            </MainDash> :
            <Navigate to='/login' />
          }
        />
        <Route
          exact
          path="/"
          element={accessToken ?
            <MainDash title="UnAnswered List" onLogout={props.onLogout}>
              <Question />
            </MainDash> :
            <Navigate to='/login' />
          }
        />
        <Route
          exact
          path="/solvedList"
          element={accessToken ?
            <MainDash title="Solved Questions List" onLogout={props.onLogout}>
              <ResolvedQuestion />
            </MainDash> :
            <Navigate to='/login' />
          }
        />
        <Route
          exact
          path="/customerReview"
          element={accessToken ?
            <MainDash title="Customer Reviews" onLogout={props.onLogout}>
              <Reviews />
            </MainDash> :
            <Navigate to='/login' />
          }
        />

        {/* <Route
          exact
          path="/privacyPolicy"
          element={accessToken ?
            <MainDash title="Privacy Policy" onLogout={props.onLogout}>
              <Privacy />
            </MainDash> :
            <Navigate to='/login' />
          }
        /> */}
      </Routes>
    </div>
  )
}
export default WebRoutes;
